import React from 'react'
import Header from '../componets/Header'
// useState for input text
import { useState ,useEffect} from 'react';
import { CalcuatePriceforDalleImage, GenrateDalleImage } from '../extra/CalcuatePriceforDalleImage'
import API_InputField from '../componets/API_InputField'
// icon help
import { FaQuestionCircle } from "react-icons/fa";
// toast
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver'
import DownloadImage from '../componets/DownloadButton';
// JSZip
import JSZip from 'jszip'
import { ExampleImage01 } from '../extra/Base64exampleImage';
import SetCropForm from '../componets/SetCropForm';

// CalcuatePriceforDalleImage
// src/extra/CalcuatePriceforDalleImage.js




export default function DalleBulkimagegenerator() {
  // model
  const [model, setmodel] = useState('dall-e-3')

  const dall_e_2_sizeList=['256x256','512x512','1024x1024']
  // dall-e-3
  const dall_e_3_sizeList=['1024x1024','1792x1024','1024x1792']
  // list of csv lines
  const [csvLines, setcsvLines] = useState([])

  // selectedSize
  const [selectedSize, setselectedSize] = useState("1024x1024")


  // finelList
  const [finelList, setfinelList] = useState([])

  // loading
  const [loading, setloading] = useState(false)

  // price
  const [price, setprice] = useState(0)


  // downliadng
  const [downliadng, setdownliadng] = useState(false)

  // progress
  const [progress, setprogress] = useState(0)
  // failed images

  // failedPrompts
  const [failedPrompts, setfailedPrompts] = useState([])


  // start from index
  const [startFromIndex, setstartFromIndex] = useState(0)


  // croping 
  const [croping, setcroping] = useState(false)
  const [cropeWidth, setcropeWidth] = useState(0)
  const [cropeHeight, setcropeHeight] = useState(0)


 async function genrrateImages(){
    // save api
    if(document.getElementById('api_key').value==''){
      toast.error('Please enter your OpenAI API key')
      return

    }
    if(csvLines.length==0){
      toast.error('Please select csv file')
      return
    }
    if(selectedSize==null){
      toast.error('Please select image size')
      return
    }
    


    const api_key=document.getElementById('api_key').value
    localStorage.setItem('api_key',api_key)

    setfailedPrompts([])
    setfinelList([])


    // console.log(api_key)
    // console.log(csvLines)
    // console.log(model)
    // console.log(selectedSize)





    try {

   

        setprogress(0)
          setloading(true)
          const _selected_size=document.getElementById('image_size').value


          
          setselectedSize(_selected_size)

          const {finaList,filedList}=await GenrateDalleImage(api_key,csvLines,model,selectedSize,setprogress,progress,setfinelList,
            finelList,startFromIndex,cropeWidth,cropeHeight,croping
          )

          // console.log(filedList)
          setfailedPrompts(filedList)



          console.log(finaList)
          // setfinelList(finaList)
          setloading(false)
          toast.success((csvLines.length - startFromIndex)+' Images generated successfully')
          
          // downloadAll()
    } catch (error) {
      setloading(false)
      console.log(error)
      toast.error('Error: '+error.message)
      
    }


  }


  function updateData(){

    if(selectedSize!=null && csvLines.length>0){

       let _price = (CalcuatePriceforDalleImage(selectedSize,model)*csvLines.length)

      console.log(_price)
      setprice(_price)
    }

  }

  useEffect(() => { 
    document.title='Dall e Bulkimage Generator'
    document.getElementById('api_key').value=localStorage.getItem('api_key')
    // from_start_index
    // document.getElementById('from_start_index').value=startFromIndex
    // setselectedSize(dall_e_2_sizeList[0])
    // console.log(selectedSize)


    updateData()

    // demodata

    // const data=[
    //   {
    //     prompt: "a car in the garage and a chicken in the pot",
    //     image: "https://oaidalleapiprodscus.blob.core.windows.net/private/org-MlSD4EE5LlzRrwly2WODVFqr/user-vCbJu4WXv8juckmrKtp5wkAI/img-SHPbhdNbKxZrkkWfmskyp5kl.png?st=2023-12-28T01%3A11%3A34Z&se=2023-12-28T03%3A11%3A34Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-12-27T18%3A52%3A37Z&ske=2023-12-28T18%3A52%3A37Z&sks=b&skv=2021-08-06&sig=8XzofpAeslbHzhZ44UJ6qQfywyjCHfVI2vc8NQ8pkgM%3D",
    //   },
    //   {
    //     prompt: "a car in the garage and a chicken in the pot",
    //     image: "https://oaidalleapiprodscus.blob.core.windows.net/private/org-MlSD4EE5LlzRrwly2WODVFqr/user-vCbJu4WXv8juckmrKtp5wkAI/img-zNOtHi57M011Jt1Tp0EAURVr.png?st=2023-12-28T01%3A11%3A41Z&se=2023-12-28T03%3A11%3A41Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-12-28T01%3A20%3A20Z&ske=2023-12-29T01%3A20%3A20Z&sks=b&skv=2021-08-06&sig=wKFtZPI%2BcvhOcYQFxNze0UCIE2czHZ1u/%2B9zD7EAP1c%3D",
    //   },
    //   {
    //     prompt: "a car in the garage and a chicken in the pot",
    //     image: "https://oaidalleapiprodscus.blob.core.windows.net/private/org-MlSD4EE5LlzRrwly2WODVFqr/user-vCbJu4WXv8juckmrKtp5wkAI/img-zNOtHi57M011Jt1Tp0EAURVr.png?st=2023-12-28T01%3A11%3A41Z&se=2023-12-28T03%3A11%3A41Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-12-28T01%3A20%3A20Z&ske=2023-12-29T01%3A20%3A20Z&sks=b&skv=2021-08-06&sig=wKFtZPI%2BcvhOcYQFxNze0UCIE2czHZ1u/%2B9zD7EAP1c%3D",
    //   }
    // ]
    const data=[
      {
        prompt: "a car in the garage and a chicken in the pot",
        image: ExampleImage01,
      },
      {
        prompt: "a car in the garage and a chicken in the pot",
        image:ExampleImage01,
      },
      {
        prompt: "a car in the garage and a chicken in the pot",
        image:ExampleImage01,
      }
    ]

    // setfinelList(data)

    const filed_list=[
      "a car in the garage and a chicken in the pot",
      "a car in the garage and a chicken in the pot",
      "a car in the garage and a chicken in the pot",
    ]
    // setfailedPrompts(filed_list)

  }, [selectedSize])

  

  async function downloadAll() {
   try {
     if (finelList.length === 0) {
       console.error('Please generate images first');
       return;
     }
 
     setdownliadng(true)
 
     // downloa images
 
     const zip = new JSZip();

    //  wait 10 sec

 
 
 
 
     for (let i = 0; i < finelList.length; i++) {
       try {
        const item = finelList[i];

        const img = item.image;
        // const filename = img.split('/').pop();
  
        const proxiedImageUrl = img;
        // randomname
        // const filename = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)+'.png';
        // file name from prompt
        const filename = item.prompt.replace(/[^a-zA-Z0-9]/g, "")+'.png';
        // put image in GenratedImages zip base64
        const binaryImg = atob(img.split(',')[1]);
        const length = binaryImg.length;
        const binaryArray = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            binaryArray[i] = binaryImg.charCodeAt(i);
        }
        zip.file("GenratedImages/"+filename, binaryArray, {binary:true});
       } catch (error) {
          console.log(error)
        
       }
 
 
 
     }

    //  zio name with date and time
      const zipName = "GenratedImages_"+new Date().toLocaleString();
 
     zip.generateAsync({type:"blob"}).then(function(content) {
       // see FileSaver.js
       saveAs(content, zipName);
   });
   setdownliadng(false)

   } catch (error) {

    console.log(error)
     toast.error('Error: '+error.message)
     setdownliadng(false)
    
   }

  
  }


  const setuptCropData=()=>{

  }
  



  return (
   <>
   <Header/>


  








    <div className="flex w-full  justify-center ">

   <div className='flex flex-col  md:max-w-xl mx-5 items-start border rounded-xl p-10' >
    <h1 className='text-center font-bold text-4xl'>Dall e Bulkimage Generator</h1>
    <p className='text-center text-gray-400'>Generate images from text with DALL-E</p>
    
  
    <br />
    
    {/* select model */}
    <input 
    id='api_key'

  
    type="password" placeholder='Enter your OpenAI API key' className='border-2 border-gray-300 rounded-md p-2 my-2   w-full '/>
   
    <p className='text-center  '>Select model</p>
    <select className='border-2 border-gray-300 rounded-md p-2 my-2 w-full' onChange={(e)=>{
      
      setmodel(e.target.value)

      updateData()
      }}>
              <option value="dall-e-3">dall e 3</option>

      <option value="dall-e-2">Dall e 2</option>
    </select>

    <p className='text-center  '>Select image size</p>


    <select className='border-2 border-gray-300 rounded-md p-2 my-2 w-full' id="image_size"

    onChange={(e)=>{


      setselectedSize(document.getElementById('image_size').value)


      // console.log(selectedSize)



      // updateData()
    }}
    
    
    >



      {
        model=='dall-e-2'?
        dall_e_2_sizeList.map((item,index)=>{
          return(
            <option key={index} value={item}>{item}</option>
          )
        })
        :
        dall_e_3_sizeList.map((item,index)=>{
          return(
            <option key={index} value={item}>{item}</option>
          )
        })
      }


    </select>

      <SetCropForm setuptCropData={setuptCropData} croping={croping} setcroping={setcroping} selectedSize={selectedSize} cropeHeight={cropeHeight} setcropeHeight={setcropeHeight} cropeWidth={cropeWidth} setcropeWidth={setcropeWidth} />
    <div className='flex flex-row justify-center items-center space-x-2 '>
    <dialog id="my_modal_help" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg">Csv file format</h3>
    <p className="py-4">Csv file should have one column with text</p>
    <div className="mockup-code">
    <pre data-prefix=""><code>prompt</code></pre> 
    <pre data-prefix=""><code>a car in the garage and a chicken in the pot</code></pre> 
    <pre data-prefix=""><code>spider man is having a fit on the corner</code></pre> 


  


</div>


    <p className="py-4">OR Csv file should have one two with text prompt,name</p>
    <div className="mockup-code">
    <pre data-prefix=""><code>prompt,name</code></pre> 
    <pre data-prefix=""><code>a car in the garage and a chicken in the pot,car</code></pre> 
    <pre data-prefix=""><code>spider man is having a fit on the corner,spiderman</code></pre> 

    </div>

    <div className="modal-action">
      <form method="dialog">
        {/* if there is a button in form, it will close the modal */}
        <button className="btn">Close</button>
      </form>
    </div>
  </div>
</dialog>

    <p className='text-center text-md '>*Pick csv file </p>
    <FaQuestionCircle onClick={()=>{
      // alert('csv file should have one column with text')
      document.getElementById('my_modal_help').showModal()
    }} className=' cursor-pointer hover:text-primary transition-all'/>

      </div>
    <input onChange={(e)=>{
      console.log(e.target.files)
      // read csv file
      const reader = new FileReader();
      reader.onload = function(e) {
        // Use reader.result
        // console.log(reader.result)
        // remove first line
        let lines=reader.result.split('\n').slice(1)
        // remove first line
        console.log(lines)
        setcsvLines(lines)
        updateData()
        // document.getElementById('from_start_index').value=0

        
      
        
      
       


      }
      reader.readAsText(e.target.files[0]);

    }} type="file" className='border-2 border-gray-300 rounded-md p-2 my-2 w-full' accept='.csv'/>
    {/* rows */}
    {
      csvLines.length>0?<>
      {/* number of lines and cost */}
      <p className='text-center text-md '>Total images: <b>{csvLines.length} </b>& Aprroximate API: <b>{price}</b> USD</p>




      </>:null
    }
   
    {
      loading&&     <progress className="progress  my-2  w-full" value={(progress+1)/csvLines.length*100} max="100"></progress>
    }
    {/* number of images */}
    {loading&& progress>=0 &&  <p className='text-center text-md '>Generated images: <b>{(progress+1)+"/"+csvLines.length}</b></p>
      
    }
    
    
{
  csvLines?.length>0&&  <>
  <input value={startFromIndex} type="number"  min={0} max={csvLines?.length-1}  id='from_start_index' placeholder='Start from index' className='border-2 border-gray-300 rounded-md p-2 my-2   w-full ' onChange={(e)=>{
    setstartFromIndex(e.target.value)
  }} required ></input>

  <p className='text-center text-md '>Specific prompt number otherwise make it 0 - {csvLines?.length-1} </p>

  </>

}
<br />

{/* start from  input */}



    <button 
    onClick={genrrateImages}

    disabled={csvLines.length==0 || loading}
    
    className='btn btn-primary btn-md w-full'>
      {
        loading?
        <div className="spinner"> Generating... </div>
        :
        'Generate images'
      }

    </button>

    {
    loading&&  <p className='text-center text-sm  text-gray-600 mt-2'>*Please don't close the tab while generating images and all images will be downloaded automatically</p>
    }
    




    <div className="divider"></div>





{/* <table className="table w-full table-compact">
  <thead>
    <tr>
      <th>Image</th>
      <th className=' flex flex-row  justify-between items-center w-full'>Prompt 
      {
        failedPrompts.length>0&&<button
        className='btn btn-sm'
        disabled={loading}
        onClick={() => {
          // Create CSV file from failed prompts
          let csvContent = "data:text/csv;charset=utf-8,";
      
          failedPrompts.forEach(function (row) {
            // Assuming each element in failedPrompts is a string
            csvContent += row + "\r\n";
          });
      
          // Download CSV file
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "failed_prompts.csv");
          document.body.appendChild(link); // Required for FF
      
          link.click(); // Trigger the click event to start the download
          document.body.removeChild(link); // Remove the link element after download
        }}
      >
       {failedPrompts.length} Failed prompt
      </button>
      }
      

      
        <button className='btn btn-primary btn-sm ' 
      disabled={ downliadng & finelList.length>=0}
      
      onClick={()=>{
        downloadAll()
      } }> {finelList.length} {

        downliadng?"Downloading...":"Download all"
      } </button></th>

    </tr>
  </thead>
  <tbody>
{
  finelList.map((item,index)=>{
    return(
      <tr key={index}>
      <td>
        <img src={item.image} alt={item.image} className='w-20 rounded-md'/>
      </td>
      <td>
        {item.prompt}
      </td>
      
    </tr>
    )
  })
}
   
  
  
  </tbody>
</table> */}



   </div>
    </div>

   </>
  )
}
