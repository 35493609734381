import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/home';
import OpenAIvisionBulkimagesFromCsv from './pages/FromCSV';
import MyLandingPage from './pages/LandingPage';
import DalleBulkimagegenerator from './pages/DalleBulkimagegenerator';
import TestImageCrop from './pages/testImage';


function App() {
  return (
    
    <>
    <BrowserRouter basename='/'>
      <Routes>
      
          <Route path="/" element={<MyLandingPage />} />
        <Route path='/OpenAI-vision-Bulk-images-from-file' element={<HomePage />} />

        <Route path='/OpenAI-vision-Bulk-images-from-csv' element={<OpenAIvisionBulkimagesFromCsv />} />
        <Route path='/dall-ebulkimagegenerator' element={<DalleBulkimagegenerator />} />
        <Route path='/testcrop' element={<TestImageCrop />} />



        
      </Routes>
    </BrowserRouter>


  
    </>
  );
}

export default App;
