import { saveAs } from 'file-saver';

export function CropNowImage({ cropWidth, cropHeight, fileName, base64Url }) {
    const img = new Image();
    img.crossOrigin = "anonymous"; // Set cross-origin attribute
    img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = cropWidth;
        canvas.height = cropHeight;
        const ctx = canvas.getContext('2d');
        // Calculate the scaling factor for covering the canvas with the image
        const scale = Math.max(cropWidth / img.width, cropHeight / img.height);
        // Calculate the size of the image after scaling
        const scaledWidth = img.width * scale;
        const scaledHeight = img.height * scale;
        // Calculate the position to draw the image to center it within the canvas
        const xOffset = (cropWidth - scaledWidth) / 2;
        const yOffset = (cropHeight - scaledHeight) / 2;
        // Draw the image onto the canvas
        ctx.drawImage(img, xOffset, yOffset, scaledWidth, scaledHeight);
        const resizedUrl = canvas.toDataURL('image/png');

        // Save the resized and cropped image
        saveAs(resizedUrl, fileName + ".png");
    };

    console.log(base64Url)

    img.src = base64Url;
}
