import axios from 'axios';

export async function verifyLicense( license_key) {
  const product_id="rmfZMHJoXoFRHgaAlWN14A==";
  try {
    const response = await axios.post(
      'https://api.gumroad.com/v2/licenses/verify',
      {
        product_id,
        license_key,
      }
    );

    // Handle the response as needed
    // console.log('Verification Response:', response.data);

    return response.data;

    // You can return or process the response further here

  } catch (error) {
    // Handle errors
    console.error('Error during license verification:', error.message);
  }
}

// // Example usage
// const product_id = 'rmfZMHJoXoFRHgaAlWN14A==';
// const license_key = '13BE1DA1-C14F4674-8F94C9E2-7C8AA8FB';

// verifyLicense(product_id, license_key);
