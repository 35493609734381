import React, { useEffect, useState } from 'react'
import Header from '../componets/Header'
// question icon from react icons
import { FaQuestionCircle } from "react-icons/fa";
import { toast } from 'react-toastify';

import { ConvertImageToBase64Async } from '../extra/ConvertFile';
import { AnalyzeImage } from '../extra/Extra';
import { DownloadExampleCSV } from '../extra/ExampleCSV';
import MyFooter from '../componets/MyFooter';
import LicnessVerify from '../componets/LicnessVerify';
import MyTable from '../componets/MyTable';



export default function OpenAIvisionBulkimagesFromCsv() {

  const [apiKey, setApiKey] = useState('')
  const [images, setImages] = useState([])
  const [imagesFinal, setImagesFinal] = useState([])
  const [progress, setProgress] = useState(0)

  // csv file
  const [csvFile, setCsvFile] = useState(null)

  // loading
  const [loading, setLoading] = useState(false)

  // temperature
  const [temperature, setTemperature] = useState(0.7)


  function saveAPIKey() {

    localStorage.setItem('apiKey', apiKey)
    const api = localStorage.getItem('apiKey')

    // temperature

    localStorage.setItem('temperature', document.getElementById("temperature").value)




  }

  function clearAPIKey() {
    // console.log('clear api key')
    //   document.getElementById('apikey').value = ''
    document.getElementById('file-input').value = null;
    setImages([])
    setImagesFinal([])
    toast.success('cleared')

  }
  async function startScan() {
    saveAPIKey();



    try {
      // console.log('start scan')

      // csv file
      if (csvFile === null) {
        toast.error('please select csv file')
        return
      }




      if (apiKey === '') {
        toast.error('please enter api key')
        return
      }







      setLoading(true);
      setImagesFinal([])

      // convert csv file 
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = (e.target.result);
        // console.log(text)
        // lines
        let lines = text.split('\n')
        lines = lines.slice(1, lines.length)
        // console.log(lines)
        for (let i = 0; i < lines.length; i++) {
          let line = lines[i]
          console.log(line)
          const prompt = line.split(',')[0]
          const url = line.split(',')[1]
          const max_tokens = document.getElementById('max_tokens').value
          const resp = await AnalyzeImage(url, apiKey, prompt, max_tokens, temperature)
          const content = resp.choices[0].message.content
          setImagesFinal((oldArray) => [...oldArray, {
            "image": url,
            "path": url,
            "prompt": prompt,
            "content": content

          }]);

          // set progress
          setProgress((i / lines.length) * 100)

          if (i == lines.length - 1) {
            toast.success('done')

            setLoading(false);
          }
        }

      }
      reader.readAsText(csvFile);









      //   await  Array.from(images).map(async (image)  => {
      //         // console.log(image);
      //       //  const resp=await analyzeImage(image,apiKey);
      //     //   read csv from file image





      //       const resp=await AnalyzeImage(url,apiKey,prompt)
      //       const content=resp.choices[0].message.content
      //       // path
      //       const path=image.name

      //       setImagesFinal((oldArray) => [...oldArray, {
      //         "image":image,
      //         "path":path,
      //         "prompt":prompt,
      //         "content":content

      //       }]);

      //       // scrol to bottom body
      //       document.body.scrollTop = document.body.scrollHeight;

      //       // console.log(data)


      //       // console.log(url)
      //       //  const data=resp.choices[0].message.content
      //       //     console.log(data)
      //      });







    } catch (error) {
      console.log(error)
      toast.error('error ' + error.message)
      setLoading(false);

    }
  }

  useEffect(() => {
    const api = localStorage.getItem('apiKey')
    setApiKey(api)
    document.getElementById('apikey').value = api
    // max_tokens
    const max_tokens = localStorage.getItem('max_tokens')
    if (max_tokens == null) {
      document.getElementById('max_tokens').value = 300
    } {
      document.getElementById('max_tokens').value = max_tokens

    }

    // temperature


    // setImagesFinal([
    //   {
    //    "image":"https://source.unsplash.com/random",
    //    "path":"https://source.unsplash.com/random",
    //    "prompt":'prompt',
    //    "content":'Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.'
    //   },
    //   {
    //    "image":"https://source.unsplash.com/random",
    //    "path":"https://source.unsplash.com/random",
    //    "prompt":'prompt',
    //    "content":'Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.'
    //   },
    //   {
    //    "image":"https://source.unsplash.com/random",
    //    "path":"https://source.unsplash.com/random",
    //    "prompt":'prompt',
    //    "content":'Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.'
    //   },
    //   {
    //    "image":"https://source.unsplash.com/random",
    //    "path":"https://source.unsplash.com/random",
    //    "prompt":'prompt',
    //    "content":'Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.'
    //   },
    //   {
    //    "image":"https://source.unsplash.com/random",
    //    "path":"https://source.unsplash.com/random",
    //    "prompt":'prompt',
    //    "content":'Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.'
    //   },
    //   {
    //    "image":"https://source.unsplash.com/random",
    //    "path":"https://source.unsplash.com/random",
    //    "prompt":'prompt',
    //    "content":'content'
    //   },{
    //    "image":"https://source.unsplash.com/random",
    //    "path":"https://source.unsplash.com/random",
    //    "prompt":'prompt',
    //    "content":'Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.'
    //   },{
    //    "image":"https://source.unsplash.com/random",
    //    "path":"https://source.unsplash.com/random",
    //    "prompt":'prompt',
    //    "content":'Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.'
    //   },{
    //    "image":"https://source.unsplash.com/random",
    //    "path":"https://source.unsplash.com/random",
    //    "prompt":'prompt',
    //    "content":'Lopresor is no longer being manufactured for sale in Canada. For brands that may still be available, search under metoprolol. This article is being kept available for reference purposes only. If you are using this medication, speak with your doctor or pharmacist for information about your treatment options.'
    //   }
    //  ])


    // console.log(api)
    // setApiKey(api)
  }, [])



  return (
    <>
      <Header />

      <div className="flex md:flex-row  flex-col w-full   md:space-x-2 space-x-0 px-2 items-start space-y-2 md:space-y-0">
        <div className=" w-full border rounded-xl">
          <div className="card-body">

            <p>Scan images in bulk with OpenAI Vission API</p>

            <div className=" flex flex-row items-center">

              <h2 className='text-gray-600'> *OpenAI API</h2>  <a href="https://platform.openai.com/api-keys" target='_blank' className="btn btn-ghost btn-sm"><FaQuestionCircle /></a>
            </div>
            <input id="apikey" onChange={(e) => {
              setApiKey(e.target.value)
            }} type="password" placeholder="sk-XXXX" className="input input-bordered w-full input-md" disabled={loading} />
            <p className=' text-gray-400 text-sm  '>Note: this api key will saved only in the your local storage</p>

            {/* temperature */}

            <div className=" text-gray-600">
              *Temperature
            </div>

            <input id="temperature" onChange={(e) => {
              setTemperature(e.target.value)
            }} type="number" placeholder="temperature" className="input input-bordered w-full input-md" disabled={loading} />
            <p className=' text-gray-400 text-sm  '>Values between 0 and 1, where 0 means no creative risks and 1 means extremely creative risks. Recommended: 0.7</p>

            <h2 className='text-gray-600'> *Max tokens</h2>

            <input id="max_tokens" type="number" placeholder="max_tokens" className="input input-bordered w-full input-md" disabled={loading} />
            <p className=' text-gray-400 text-sm  '>Larger tokens values  will increase the length of the returned text.</p>




            <div className=" text-gray-600">
              *Select csv file
            </div>
            <input
              id="file-input"
              onChange={(e) => {
                // console.log(e.target.files)
                // setImages(e.target.files)
                setCsvFile(e.target.files[0])
              }}

              type="file" className="file-input file-input-bordered w-full file-input-md" disabled={loading} />

            <p className=' text-sm text-gray-500'>Csv file must have a column name <br /> "image,prompt", you can also download the sample csv file</p>
            {
              loading ? <progress className="progress " value={progress} max="100"></progress> : <> </>
            }
            <br />

            <br />
            <div className="card-actions justify-end">


              <button
                onClick={DownloadExampleCSV}
                className="btn ">Download sample csv</button>



              <button
                onClick={clearAPIKey}
                className="btn ">Clear</button>

              {
                loading ? <button className="btn btn-primary" disabled={loading}>Loading...</button> : <button onClick={startScan} className="btn btn-primary">Start</button>
              }


            </div>
            {
              imagesFinal.length > 0 ? <h1>*scorll to bottom to see the results</h1> : <> </>
            }
            <br />
          </div>

        </div>
        {



          <div className=' h-screen overflow-auto  w-full'>

            <MyTable imagesFinal={imagesFinal} isCsv={true} />


          </div>

        }
      </div>
      <LicnessVerify />


      <MyFooter />





    </>

  )
}
