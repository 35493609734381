import React,{useEffect, useState} from 'react'
import Header from '../componets/Header'
import MyFooter from '../componets/MyFooter'
// check icon from react icons
import { FaCheckCircle } from "react-icons/fa";
// use navigation
import { useNavigate } from 'react-router-dom';


export default function MyLandingPage() {
  const navigate = useNavigate();
  useEffect(() => {

    const license_key=localStorage.getItem('license_key')
    
    console.log(license_key)
    if(license_key!=null && license_key!=''){
      // navigate('/OpenAI-vision-Bulk-images-from-file')
    
   


    }


}, [])

  return (
    < >
    <Header/>
    

   <div className='flex flex-col justify-center items-center px-10 ' >

   <h1 className=' md:text-6xl text-4xl font-bold text-center md:max-w-4xl mt-10 '>Scan Images in Bulk with <span className='text-blue-500'>OpenAI vision Bulk images</span> </h1>
    
    <p className='text-center font-bold  max-w-md md:mt-10 mt-5'>Extract valuable information from your images quickly and effortlessly with "OpenAI vision Bulk images" powerful image scanning tool powered by OpenAI Vision API.</p>
    <img  src='demo_video.gif' alt='screen01' className='rounded-2xl  md:w-1/2  my-5 md:my-10 '/>




    <h3 className='text-center  font-bold md:pt-10'>Features</h3>
    
    <div className='flex flex-row justify-start items-center'>
    <FaCheckCircle className='text-green-500'/>
    <p className='px-2'>No monthly fees</p>
    </div>


    <div className='flex flex-row justify-start items-center'>
    <FaCheckCircle className='text-green-500'/>
    <p className='px-2'>Upload image or csv file</p>
    </div>
    <div className='flex flex-row justify-start items-center'>
    <FaCheckCircle className='text-green-500'/>
    <p className='px-2'>Export as csv and view table</p>
    </div>
    <div className='flex flex-row justify-start items-center'>
    <FaCheckCircle className='text-green-500'/>
    <p className='px-2'>use your own OpenAI API</p>
    </div>

   <div className="flex flex-row space-x-1 items-center">
   <a href='OpenAI-vision-Bulk-images-from-file' className='btn btn-primary mt-10'>Get started</a>
    <a href="https://app.gumroad.com/checkout?product=womcix&quantity=1" className='btn mt-10'>Buy license</a>
   </div>
   <br />
   <a href="https://www.producthunt.com/posts/openai-vision-bulk-images?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-openai&#0045;vision&#0045;bulk&#0045;images" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=429543&theme=light" alt="OpenAI&#0032;vision&#0032;Bulk&#0032;images - CSV&#0032;file&#0032;&#0124;GPT&#0045;4&#0045;Vision&#0032;&#0124;&#0032;Image&#0032;analysis&#0032;with&#0032;AI | Product Hunt" /></a>

   <p className='text-center text-gray-400 text-sm pt-10 max-w-xl '>Note: this is a one time payment, you will get a license key after payment, you can use this license key to activate the product</p>


   </div>
   <br />
    <br />
    <br />
    <MyFooter/>
    </>
  )
}
