import React,{useState,useEffect} from 'react'
import { toast } from 'react-toastify'

// croping={croping} setcroping={setcroping} selectedSize={selectedSize} cropeHeight={cropeHeight} setcropeHeight={setcropeHeight} cropeWidth={cropeWidth} setcropeWidth={setcropeWidth}
export default function SetCropForm({croping,setcroping,selectedSize,cropeHeight,setcropeHeight,cropeWidth,setcropeWidth,setuptCropData}) {

    useEffect(() => {
        setcropeHeight(localStorage.getItem('cropeHeight')??0)
        setcropeWidth(localStorage.getItem('cropeWidth')??0)
        // setcroping(localStorage.getItem('croping')??false)
        
    }, [])

    // useEffect(() => {
    //   localStorage.setItem('croping',croping)
        
    // }, [croping])
  return (
   
    <div className='rounded-md border p-2 my-4 w-full'>
    <div className=' flex flex-row items-center space-x-1 '>
    <input 
     onClick={()=>{
       setcroping(!croping)
     }}

     value={croping}
    
     type="checkbox"  className="checkbox checkbox-sm" />
     <p className='text-center  '>Set image crop (optionnal)</p>
    </div>
     <div className=' flex md:flex-row flex-col gap-2 mt-5'>
       
      
       {
         croping &&   <input 
         
         value={cropeWidth  }
         onChange={(e)=>{
           setcropeWidth(e.target.value)
          
         }}
         className='input input-sm  input-bordered' type="number" placeholder='width' id='width'  />
       }
 
       { 
       croping &&       <input 
       
       value={cropeHeight}
       onChange={(e)=>{
         setcropeHeight(e.target.value)
       }}
       className='input input-sm input-bordered' type="number" placeholder='height' id='height'  />
 
       
       }
 
 
 
      
 
     
 
 
     </div>
     {
         croping && <button className='btn btn-sm mt-5' onClick={
           ()=>{
               localStorage.setItem('cropeWidth',cropeWidth)
               localStorage.setItem('cropeHeight',cropeHeight)
               toast.success('Updated')

           }
         }>Update</button>
       }
       {
         croping &&    <p className='text-xs italic text-gray-400 pt-2   '>Note: this option will only crop the image if you set the width and height from {selectedSize}</p>      }
     </div>
 
 
  )
}
