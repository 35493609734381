import React from 'react';
import { saveAs } from 'file-saver';
import { TestOpenAiAPI } from '../extra/TestAPI';

export default function TestImageCrop() {
    const url =
        'https://plus.unsplash.com/premium_photo-1664457233868-d2a40c759998?q=80&w=3328&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
   // When the image is loaded, perform resizing and cropping
    const cropNow = () => {
        const fileName = "abc";
        const cropWidth = 100;
        const cropHeight = 512;

        const img = new Image();
        img.crossOrigin = "anonymous"; // Set cross-origin attribute
        img.onload = function () {
            const canvas = document.createElement('canvas');
            canvas.width = cropWidth;
            canvas.height = cropHeight;
            const ctx = canvas.getContext('2d');
            // Calculate the scaling factor for covering the canvas with the image
            const scale = Math.max(cropWidth / img.width, cropHeight / img.height);
            // Calculate the size of the image after scaling
            const scaledWidth = img.width * scale;
            const scaledHeight = img.height * scale;
            // Calculate the position to draw the image to center it within the canvas
            const xOffset = (cropWidth - scaledWidth) / 2;
            const yOffset = (cropHeight - scaledHeight) / 2;
            // Draw the image onto the canvas
            ctx.drawImage(img, xOffset, yOffset, scaledWidth, scaledHeight);
            const resizedUrl = canvas.toDataURL('image/png');

            // Save the resized and cropped image
            saveAs(resizedUrl, fileName + ".png");
        };

        img.src = url;
    };

    async function  testAPi(){
        const valid=await TestOpenAiAPI("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")
        console.log(valid)
    }

    

    return (
        <>
            <button className='btn btn-primary' onClick={testAPi}>Test</button>
        </>
    );
}
