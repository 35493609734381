import axios from 'axios';
import base64 from 'base-64';

export async function AnalyzeImage(file_url, apiKey, prompt, max_tokens, temperature = 0.7) {
  console.log("file_url:", file_url, "apiKey:", apiKey, "prompt:", prompt, "max_tokens:", max_tokens);

  console.log("api:", apiKey);
  try {
    // Function to encode the image

    // apiKey="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"




    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${apiKey}`
    };

    const payload = {
      "model": "gpt-4o",
      "messages": [
        {
          "role": "user",
          "content": [
            {
              "type": "text",
              "text": prompt,
            },
            {
              "type": "image_url",
              "image_url": {
                "url": file_url,
              }
            }
          ]
        }
      ],
      "max_tokens": parseInt(max_tokens),
      temperature: typeof temperature === 'string' ? parseFloat(temperature) : temperature
    };

    const response = await axios.post("https://api.openai.com/v1/chat/completions", payload, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
}
