// DALL·E 3	Standard	1024×1024	$0.040 / image
// Standard	1024×1792, 1792×1024	$0.080 / image
// DALL·E 3	HD	1024×1024	$0.080 / image
// HD	1024×1792, 1792×1024	$0.120 / image


// DALL·E 2		1024×1024	$0.020 / image
// 512×512	$0.018 / image
// 256×256	$0.016 / image
import OpenAI from "openai";
import { saveAs } from 'file-saver'
import { CropNowImage } from "./CropHelper";
import { toast } from "react-toastify";
import { TestOpenAiAPI } from "./TestAPI";



export function CalcuatePriceforDalleImage(size, model,isHd=false){
    // model dall-e-2 
    // / dall-e-3

    let price=0;


    if (model=="dall-e-3"){
        if (size=='1024x1792' ){
            price=0.040;
    
        }else if (size=='1024x1792'||size=='1792x1024' ){
            price=0.080;
        }else if (isHd && size=='1024x1024'){
            price=0.080;
        }else if (isHd && size=='1024x1792'||size=='1792x1024' ){
            price=0.120;
        }
        


    }else if (model=="dall-e-2"){
        if (size=='1024x1024'){
            price=0.020;
        }else if (size=='512x512'){
            price=0.018;
        }else if (size=='256x256'){
            price=0.016;
        }
    }

    console.log("price:",price);
    console.log("size:",size);
    console.log("model:",model);

 
    return price;

}



export async function GenrateDalleImage(apikey,csv_list,model,size,setprogress,progress,setfinelList,finelList,startFromIndex,
    cropeWidth,cropeHeight,croping

){

    console.log("apikey:",apikey);

    console.log("model:",model);
    console.log("size:",size);
    console.log("_selected_from_start:",startFromIndex);


    



    

let openai = new OpenAI({apiKey:apikey,dangerouslyAllowBrowser: true });


let finaList=[];
let filedList=[];

const valid=await TestOpenAiAPI(apikey)
if (!valid){
  toast.error('Please check your OpenAI API key and make sure you have vaild payment at https://platform.openai.com/')
  return []
}


for (let i = startFromIndex; i < csv_list.length; i++) {
 try {
      let  _prompt=csv_list[i];

      let finleName=csv_list[i].substring(0, 200).replace(/[^a-zA-Z0-9]/g, "_")+"_"+i;


      if(csv_list[i].includes(",")){
        _prompt=csv_list[i].split(",")[0];
        finleName=csv_list[i].split(",")[1];
      }



        console.log("prompt:",_prompt);
   
       const image = await openai.images.generate({ model: model, prompt:_prompt,size:size,response_format: "b64_json"});
       
       // console.log(image.data[0].b64_json);
       // console.log(image.data[0].url);
   
       const url="data:image/png;base64,"+image.data[0].b64_json;
    //    console.log(url);
   
   
       // console.log(image[0].image);
   
   
       const data={
           "image":url,
           "prompt":_prompt
       }


    //    finaList.push(data);
    //    setfinelList((finaList) => [...finaList, data]);
    //    intinal 200 char  _ i




    //   

    if(croping && cropeWidth > 1 && cropeHeight > 1){
        console.log("croping: ",url);
        CropNowImage({
            cropWidth: cropeWidth,
            cropHeight: cropeHeight,
            fileName: finleName,
            base64Url: url});


    }else{
        console.log("not croping");

        saveAs(url, finleName+".png");
    }
    

       // progress by 100 %
       setprogress(i);
 } catch (error) {
    filedList.push(csv_list[i]);
    console.log(error);
    setprogress(i);

    
 }



}




//   console.log(finaList);

  return {finaList,filedList};


}