


export function DownloadExampleCSV() {
  const imagesFinal =[
    {
      "image_url": "https://plus.unsplash.com/premium_photo-1669223469435-27e091439169?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8c3RhcnR8ZW58MHx8MHx8fDA%3D",
      "prompt": "What are in this image?",
  
  
    }
  ]
   
  
    // Prepare the CSV content
    const csvContent = "prompt,image_url,\n" +
      imagesFinal.map(image => `${image.prompt},${image.image_url}`).join('\n');
  
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  
    // Create a download link and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'example.csv';
    link.click();

    
}