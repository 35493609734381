export function SaveCSVwithFile(imagesFinal) {
    // Check if imagesFinal is not empty
    if (!imagesFinal || imagesFinal.length === 0) {
      console.error('No data to save.');
      return;
    }
  
    // Prepare the CSV content
    const csvContent = "path,prompt,response\n" +
      imagesFinal.map(image => `${image.path},${image.prompt},${image.content}`).join('\n');
  
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  
    // Create a download link and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'output.csv';
    link.click();
  }

  export function SaveCSVwithCSV(imagesFinal) {
    // Check if imagesFinal is not empty
    if (!imagesFinal || imagesFinal.length === 0) {
      console.error('No data to save.');
      return;
    }
  
    // Prepare the CSV content
    const csvContent = "prompt,response,url\n" +
      imagesFinal.map(image => `${image.prompt},${image.content},${image.image}`).join('\n');
  
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  
    // Create a download link and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'output.csv';
    link.click();
  }