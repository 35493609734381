import React, { useEffect, useState } from 'react'
import Header from '../componets/Header'
// question icon from react icons
import { FaQuestionCircle } from "react-icons/fa";
import { toast } from 'react-toastify';

import { ConvertImageToBase64Async } from '../extra/ConvertFile';
import { AnalyzeImage } from '../extra/Extra';
import MyTable from '../componets/MyTable';
import MyFooter from '../componets/MyFooter';
import { verifyLicense } from '../extra/VerifyLinncess';
import LicnessVerify from '../componets/LicnessVerify';
import { CalculatePriceForOpenAI } from '../extra/CalculatePrice';
// CalculatePriceForOpenAI


function HomePage() {
  const [apiKey, setApiKey] = useState('')
  const [images, setImages] = useState([])
  const [imagesFinal, setImagesFinal] = useState([])
  // progress
  const [progress, setProgress] = useState(0)

  // loading
  const [loading, setLoading] = useState(false)

  // temperature
  const [temperature, setTemperature] = useState(0.7)



  function saveAPIKey() {

    localStorage.setItem('apiKey', apiKey)
    const api = localStorage.getItem('apiKey')
    localStorage.setItem('promt', document.getElementById("prompt").value)
    // max_tokens

    localStorage.setItem('max_tokens', document.getElementById("max_tokens").value)
    // temperature

    localStorage.setItem('temperature', document.getElementById("temperature").value)


  }

  function clearAPIKey() {
    // console.log('clear api key')
    //   document.getElementById('apikey').value = ''
    document.getElementById('file-input').value = null;
    setImages([])
    setImagesFinal([])


    toast.success('cleared')
    // 

  }
  async function startScan() {
    saveAPIKey();

    try {
      // console.log('start scan')

      const prompt = document.getElementById("prompt").value

      if (apiKey == '') {
        toast.error('please enter api key')
        return
      }

      if (images.length === 0) {
        toast.error('please select images')

        return
      }
      if (apiKey === '') {
        toast.error('please enter api key')
        return
      }
      if (prompt == '') {
        toast.error('please enter prompt key')

        return
      }



      setImagesFinal([])




      // console.log(data)
      //   console.log("done")
      setLoading(true);


      // imagesFinal.map((image, index) => {
      //   CalculatePriceForOpenAI(image)

      // })

      // for (let i = 0; i < images.length; i++) {

      // }


      // return




      for (let i = 0; i < images.length; i++) {
        try {
          const image = images[i]
          console.log(image);
          var bs62string = await ConvertImageToBase64Async(image)
          var url = "data:image/png;base64," + bs62string
          const max_tokens = document.getElementById("max_tokens").value

          const resp = await AnalyzeImage(url, apiKey, prompt, max_tokens, temperature)
          const content = resp.choices[0].message.content
          // path
          const path = image.name

          setImagesFinal((oldArray) => [...oldArray, {
            "image": image,
            "path": path,
            "prompt": prompt,
            "content": content

          }]);

          // scrol to bottom body
          document.body.scrollTop = document.body.scrollHeight;

          setProgress((i + 1) / images.length * 100)

          if (i == images.length - 1) {
            toast.success('done')
            setLoading(false);
          }
        } catch (error) {
          console.log(error)


        }
      }












      // setLoading(false);





    } catch (error) {
      console.log(error)
      toast.error('error ' + error.message)
      setLoading(false);

    }
  }

  useEffect(() => {
    const api = localStorage.getItem('apiKey')
    setApiKey(api)
    document.getElementById('apikey').value = api
    const prompt = localStorage.getItem('promt')
    document.getElementById('prompt').value = prompt
    const max_tokens = localStorage.getItem('max_tokens')
    if (max_tokens == null) {
      document.getElementById('max_tokens').value = 300
    } {
      document.getElementById('max_tokens').value = max_tokens

    }




  }, [])



  return (
    <>
      <Header />

      <div className="flex md:flex-row  flex-col w-full   md:space-x-2 space-x-0 px-2 items-start space-y-2 md:space-y-0  pb-12 justify-start">

        <div className="  w-full border rounded-xl  ">
          <div className="card-body">

            <p>Scan images in bulk with OpenAI Vission API</p>

            <div className=" flex flex-row items-center">

              <h2 className='text-gray-600'> *OpenAI API</h2>  <a href="https://platform.openai.com/api-keys" target='_blank' className="btn btn-ghost btn-sm"><FaQuestionCircle /></a>
            </div>
            <input id="apikey" onChange={(e) => {
              setApiKey(e.target.value)
            }} type="password" placeholder="sk-XXXX" className="input input-bordered w-full input-md" disabled={loading} />
            <p className=' text-gray-400 text-sm  '>Note: this api key will saved only in the your local storage</p>

            {/* temperature */}

            <p className='text-gray-600'> *Temperature</p>
            <input id="temperature" onChange={(e) => {
              setTemperature(e.target.value)
            }} type="number" step={0.1} placeholder="temperature" className="input input-bordered w-full input-md" disabled={loading} min={0.0} max={1.0} defaultValue={0.5} />
            <p className=' text-gray-400 text-sm  '>Note: this temperature will be used for all the images</p>





            <div className=" flex flex-row items-center">

              <h2 className='text-gray-600'> *Prompts</h2>
            </div>
            <input id="prompt" type="text" placeholder="What are this in this image?" className="input input-bordered w-full input-md" disabled={loading} />
            <p className=' text-gray-400 text-sm  '>Note: this prompt will be used for all the images</p>


            <h2 className='text-gray-600'> *Max tokens</h2>

            <input id="max_tokens" type="number" placeholder="max_tokens" className="input input-bordered w-full input-md" disabled={loading} />

            <p className=' text-gray-400 text-sm  '>Larger tokens values  will increase the length of the returned text.</p>





            <div className="label  text-gray-600">
              *Select images
            </div>
            <input
              id="file-input"
              onChange={(e) => {
                console.log(e.target.files)
                setImages(e.target.files)
              }}

              type="file" className="file-input file-input-bordered w-full file-input-md" multiple disabled={loading} />

            {
              loading ? <progress className="progress " value={progress} max="100"></progress> : <> </>
            }


            <br />



            <div className="card-actions justify-end">
              <a

                href='OpenAI-vision-Bulk-images-from-csv'

                className="btn ">Want to upload csv?</a>

              <button
                onClick={() => {
                  clearAPIKey();
                  // setLoading(true);
                  // console.log(loading)
                }}
                className="btn ">Clear</button>

              {
                loading ? <button className="btn btn-primary" disabled={loading}>Loading...</button> : <button onClick={startScan} className="btn btn-primary">Start</button>
              }


            </div>
            {
              imagesFinal.length > 0 ? <h1>*Scroll to bottom to see the results</h1> : <> </>
            }
            <br />
          </div>
        </div>

        {



          <div className=' h-screen overflow-auto  w-full'>
            <MyTable imagesFinal={imagesFinal} isCsv={false} />

          </div>


        }
      </div>


      <LicnessVerify />





      <MyFooter />

    </>

  )
}

export default HomePage