import React from 'react'
// vertical menu icon
import { FaBars } from "react-icons/fa";
import { useHistory ,useLocation } from 'react-router-dom';


export default function () {
  const location = useLocation()

  console.log(location.pathname)


  return (
   <>
  <div className="navbar ">
  <div className="flex-1">
    <a  href="/" className="btn btn-ghost text-xl">OpenAI vision Bulk images</a>
  </div>
  
  <div className="dropdown dropdown-end  ">
        <div tabIndex={0} role="button" className="btn btn-ghost rounded-btn"><FaBars/></div>
        <ul tabIndex={0} className="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box  mt-4">
          <li><a  href="OpenAI-vision-Bulk-images-from-file"  >OpenAI vision Bulk images from file</a></li> 
          <li><a  href="OpenAI-vision-Bulk-images-from-csv"  >OpenAI vision Bulk images from csv</a></li> 
          {/* <li><a  href='dall-ebulkimagegenerator'> Dall e bulkimagegenerator</a></li> */}

          <li><a  href="https://openai.com/pricing"  >How much will api costs?</a></li> 


          <br />
          <h1 className=' text-gray-400 text-sm  '>More products</h1>
          <li><a target='_blank' href='https://fluttydev.gumroad.com/l/QuickbotAItrainchatgptonyourowndataAIchatbotDevelopmentSolution?layout=profile'> QuickbotAI</a></li>

          <li><a  href='http://dall-ebulkimagegenerator.com/' target='_blank'> dall-ebulkimagegenerator</a></li>
          <li><a href='https://openaiapitest.surge.sh/' target='_blank'> OpenAI API Test</a></li>
          <li><a href='mailto:um.waqas.khan@gmail.com' target='_blank'>Support</a></li>




        </ul>
      </div>
      {
    location.pathname=='/'?   <a href='OpenAI-vision-Bulk-images-from-file' className='btn btn-primary'>Get started</a>:null

  }
  
</div>
   </>
  )
}
