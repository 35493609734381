import OpenAI from 'openai';


export  async function TestOpenAiAPI(apiKey){
   try {
     const openai = new OpenAI({
         apiKey: apiKey,
         dangerouslyAllowBrowser: true,
       });
 
       const completion = await openai.chat.completions.create({
         messages: [{ role: 'user', content: 'Say this is a test' }],
         model: 'gpt-3.5-turbo',
         max_tokens: 2,
        
       });
 
       return completion.choices[0].message.content!=null
 
   } catch (error) {
    return false
    
   }

     
}