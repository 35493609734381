import React from 'react';
import { SaveCSVwithCSV, SaveCSVwithFile } from '../extra/SaveCSV';
export default function MyTable(props) {
  const { imagesFinal } = props;
  const { isCsv } = props
  // path,prompt,  response




  return (
    <>
      <div className=" border rounded-xl  w-full">
        <div className="flex flex-row justify-end">
          <button
            onClick={() => {
              if (isCsv) {
                SaveCSVwithFile(imagesFinal)
              } else {
                SaveCSVwithCSV(imagesFinal)
              }
            }}
            className="btn btn-ghost">Export csv</button>
        </div>
        <table className="table w-full     h-[1024] max-h-fit">
          {/* head */}
          <thead>
            <tr>



              {
                isCsv ? <>
                  <th>image</th>
                  <th>path/ response</th>
                </> : <>
                  <th>image</th>
                  <th>response</th>
                </>
              }





            </tr>

          </thead>

          <tbody className=" ">
            {imagesFinal?.map((image, index) => (
              <tr key={index}>








                {
                  isCsv ? <>
                    <td>
                      <img
                        className="w-10 h-10 rounded-md"
                        src={image.path}
                        alt=""
                      />
                    </td>
                    <td><div className=' flex flex-col tex-sm text-gray-600 ' >
                      Path: {image.path} <br />
                      {image.content}</div>

                    </td>
                  </>
                    : <>
                      <td>

                        <img
                          className="w-10 h-10 rounded-md"
                          src={URL.createObjectURL(image.image)}

                          alt=""
                        />
                      </td>
                      <td><div className=' flex flex-col tex-sm text-gray-600' >
                        Path: {image.path} <br />
                        {image.content}</div>
                      </td>
                    </>
                }

              </tr>
            ))}
          </tbody>
        </table>

      </div>

    </>
  );
}
