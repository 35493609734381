import React,{useEffect, useState} from 'react'
import { toast } from 'react-toastify';
import { verifyLicense } from '../extra/VerifyLinncess';


export default function LicnessVerify() {

  // loading
  const [loading, setLoading] = useState(false)

  useEffect(() => {

    const license_key=localStorage.getItem('license_key')
    
    console.log(license_key)
    if(license_key!=null && license_key!=''){
      // document.getElementById('license_key').value=license_key
      // document.getElementById('my_modal_1').close()

      // verifyLicense(license_key).then(resp=>{
      //   console.log(resp)
      //   if(resp.success){
      //     document.getElementById('verifycation_modal').close()
      //   }{
      //     document.getElementById('verifycation_modal').showModal()
      //   }
      // })
      document.getElementById('verifycation_modal').close()


    }else{
      document.getElementById('verifycation_modal').showModal()
    }
    


}, [])
  return (
   <>
   
<dialog id="verifycation_modal" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg">Do you like  OpenAI vision Bulk images?</h3>
    <p className="py-4">OpenAI vision Bulk images | CSV file</p>
    <h1>Features</h1>
    <ul className="list-disc list-inside">
      {/* unlimted use, free updates,  */}
      <li>Unlimited use</li>
      <li>Free updates</li>
      <li>Use your own OpenAI API</li>

      <li>support</li>
      
    </ul>
    <br />

    <p className="py-4">Please consider supporting us by buying a licnece for this tool</p>

    <h1 className='text-gray-600'> *License key</h1>
    <br />

    <input id="license_key" disabled={loading} type="text" placeholder="rmfZMHJoXoFRHgaAlWN14A==" className="input input-bordered input-md w-full" />
    <br /> 
    <br />
    
<div className='flex flex-row space-x-2'>

<button 
disabled={loading}
onClick={async()=>{
  try {
    const license_key=document.getElementById('license_key').value
  
    if(license_key==''){
      // toast.error('please enter license key')
      return
    }
    setLoading(true)
  
    
  
    const resp=await verifyLicense(license_key);
  
    if(resp.success){
      toast.success('verified')
      localStorage.setItem('license_key',license_key)
  
      document.getElementById('verifycation_modal').close()
      setLoading(false)
    }
    console.log(resp)
  } catch (error) {
    toast.error("Something went wrong")
    setLoading(false)
    
  }
}}

className="btn btn-neutral">
  {
    loading?
    <span className="loading loading-spinner loading-md"></span>
    :
    'Verify'
  }
  </button> 


    <a href='https://app.gumroad.com/checkout?product=womcix&quantity=1' target='_blank' className="btn btn-primary">Purchase now</a> 
</div>

<br />


 
  </div>
</dialog>
   
   </>
  )
}
